@charset 'utf-8'

@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 200
    src: local(''), url('../fonts/oswald-v41-latin-200.woff2') format('woff2'), url('../fonts/oswald-v41-latin-200.woff') format('woff')

@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 400
    src: local(''), url('../fonts/oswald-v41-latin-regular.woff2') format('woff2'), url('../fonts/oswald-v41-latin-regular.woff') format('woff')

@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 300
    src: local(''), url('../fonts/oswald-v41-latin-300.woff2') format('woff2'), url('../fonts/oswald-v41-latin-300.woff') format('woff')

@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 500
    src: local(''), url('../fonts/oswald-v41-latin-500.woff2') format('woff2'), url('../fonts/oswald-v41-latin-500.woff') format('woff')

@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 600
    src: local(''), url('../fonts/oswald-v41-latin-600.woff2') format('woff2'), url('../fonts/oswald-v41-latin-600.woff') format('woff')

@font-face
    font-family: 'Oswald'
    font-style: normal
    font-weight: 700
    src: local(''), url('../fonts/oswald-v41-latin-700.woff2') format('woff2'), url('../fonts/oswald-v41-latin-700.woff') format('woff')

@font-face
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 300
    src: local(''), url('../fonts/open-sans-v27-latin-300.woff2') format('woff2'), url('../fonts/open-sans-v27-latin-300.woff') format('woff')

@font-face
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 400
    src: local(''), url('../fonts/open-sans-v27-latin-regular.woff2') format('woff2'), url('../fonts/open-sans-v27-latin-regular.woff') format('woff')

@font-face
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 600
    src: local(''), url('../fonts/open-sans-v27-latin-600.woff2') format('woff2'), url('../fonts/open-sans-v27-latin-600.woff') format('woff')

@font-face
    font-family: 'Open Sans'
    font-style: normal
    font-weight: 700
    src: local(''), url('../fonts/open-sans-v27-latin-700.woff2') format('woff2'), url('../fonts/open-sans-v27-latin-700.woff') format('woff')

// Update Bulma's global variables
$family-sans-serif: 'Open Sans', sans-serif
$family-sans-serif-alternative: 'Oswald', sans-serif

// Brand colors
$white: #ffffff
$black: #444444
$grey: hsl(0, 0%, 48%)

$background: #ffffff

$primary: #ff6900
$primary-light: #ff9144

$navbar-height: 5rem
$navbar-background-color: $white
$navbar-item-img-max-height: 3rem
$navbar-item-hover-color: $primary
$navbar-dropdown-arrow: $grey
$navbar-dropdown-item-hover-color: $primary

$tabs-link-active-border-bottom-color: $primary
$tabs-link-active-color: $primary

$input-color: $white
$input-border-color: hsl(0, 0%, 21%)
$input-focus-border-color: hsl(0, 0%, 21%)
$input-background-color: hsl(0, 0%, 48%)

$footer-background-color: rgba(45, 51, 56, 1.00)

// Import only what you need from Bulma
@import '../../node_modules/bulma/bulma.sass'

// Vimeo
.vimeo-player
    padding: 56.25% 0 0 0
    position: relative

    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.font-secondary, h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5
    font-family: $family-sans-serif-alternative

.my-heading
    margin-top: 3rem

    &-nomargin
        @extend .my-heading
        margin-top: 0

    h3
        margin-bottom: 0
        font-weight: 700
        font-size: 16px
        letter-spacing: 0.15rem

    h2
        font-size: 32px
        margin: 0.5rem 0
        font-weight: 500
        color: #000
        position: relative
        padding-bottom: 16px

        &:before
            content: ''
            left: 50%
            bottom: 0
            -webkit-transform: translateX(-50%)
            -ms-transform: translateX(-50%)
            transform: translateX(-50%)
            position: absolute
            width: 40px
            height: 2px
            background: $primary

.my-heading-sub
    margin: 0
    padding: 3rem

    h3
        margin-bottom: 0
        font-weight: 700
        font-size: 16px
        letter-spacing: 0.15rem

    h2
        font-size: 32px
        margin: 0.5rem 0 3rem
        font-weight: 500
        color: #000
        position: relative
        padding-bottom: 16px

        &:before
            content: ''
            left: 0
            bottom: 0
            position: absolute
            width: 40px
            height: 2px
            background: $primary

.my-link
    color: $black
    text-decoration: underline
    text-decoration-style: dotted

.my-modal

    a.card-footer-item
        color: $black

    .media-content

        h1
            font-size: 22px

        h2
            font-size: 12px
            letter-spacing: 0.1rem

.my-justify
    text-align: justify

.my-marginless
    margin-left: 0.75rem
    margin-right: 0.75rem

.my-modal-close
    color: $white
    text-align: right
    cursor: pointer
    font-size: 0.8rem

.my-equal-height-card
    display: flex
    flex-direction: column
    height: 100%

    .card-footer
        margin-top: auto

        .card-footer-item
            color: $black

            &:hover
                color: $primary

.my-mobile-small
    @media (max-width: 768px)
        width: 48px !important
        height: 48px !important

.site-wrap
    max-width: 1200px
    margin: 0 auto
    overflow: hidden
    position: relative
    -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1)
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1)
    background: #fff

    .button
        border-radius: 0

    &:before
        -webkit-transition: .3s all ease-in-out
        -o-transition: .3s all ease-in-out
        transition: .3s all ease-in-out
        background: rgba(0, 0, 0, 0.6)
        content: ""
        position: absolute
        z-index: 2000
        top: 0
        left: 0
        right: 0
        bottom: 0
        opacity: 0
        visibility: hidden

    .navbar

        .navbar-item
            font-size: 1rem
            letter-spacing: 0.07rem

            .active
                color: $white
                background-color: $primary
                font-weight: bold
                padding: 0.3rem 1rem

    .carousel

        .owl-carousel
            height: 600px

            .item
                min-height: 700px

        .owl-carousel.small
            height: 300px

            .item
                min-height: 400px

        .item
            background-position: center center

            &.first
                background-image: url('../assets/images/carousel-1.jpg')

            &.second
                background-image: url('../assets/images/carousel-2.jpg')

            &.third
                background-image: url('../assets/images/carousel-3.jpg')

            &.fourth
                background-image: url('../assets/images/carousel-4.jpg')

            .texts
                position: absolute
                top: 50%
                left: 50%
                transform: translate(-50%, -50%)
                max-width: 96%

                .text
                    font-size: 4.2rem
                    font-weight: 700
                    font-family: $family-sans-serif-alternative
                    color: $white
                    text-shadow: 2px 2px 8px $grey

                .subtext
                    font-size: 1.2rem
                    font-weight: 500
                    font-family: $family-sans-serif
                    color: $white
                    letter-spacing: 0.1rem
                    text-shadow: 2px 2px 8px $grey

        .owl-theme
            .custom-nav
                position: absolute
                top: calc(50% - 80px)
                left: 0
                right: 0

                .owl-prev, .owl-next
                    position: absolute
                    width: 50px
                    height: 50px
                    color: $white
                    background-color: rgba(0, 0, 0, 0.40)
                    border: none
                    border-radius: 0
                    z-index: 100

                .owl-prev
                    left: 0

                .owl-next
                    right: 0

        .slant
            height: 110px
            position: relative
            z-index: 4
            border: 0 solid #fff

            &:before
                position: absolute
                width: 200%
                content: ""
                background: #fff
                top: -60px
                left: 0
                right: 0
                bottom: 0
                -webkit-transform: rotate(-5deg) translateX(-20%)
                -ms-transform: rotate(-5deg) translateX(-20%)
                transform: rotate(-5deg) translateX(-20%)

    &.index

        .tree
            margin: 4rem 0 8rem 0

        .welcome
            margin: 4rem 0 8rem 0

            .button
                border-radius: 0

        .speakers, .organizers
            margin: 4rem 0 8rem 0

            .is-paddingless
                line-height: 0

            .user-tiles
                padding-top: 3rem !important

                .user-tile
                    cursor: pointer

    &.keynotes

        .keynote
            margin: 4rem 2rem 8rem 2rem

            .card
                width: 100% // IE11 Bug

                .compress-expand
                    border: none
                    border-radius: 0 0 3px 3px
                    transform: rotate(270deg)
                    transform-origin: top left
                    top: 190px
                    box-shadow: rgba(68, 68, 68, 0.1) -3px 8px 8px 0px

                @media screen and (max-width: 1023px)

                    .chat

                        iframe
                            height: 400px

                    .compress-expand
                        position: relative
                        top: 14px
                        left: 24px
                        border-radius: 3px
                        border: 1px solid #d9d9d9
                        transform: none
                        box-shadow: none

                .media-content

                    h1
                        font-size: 2.5rem
                        line-height: 3.4rem
                        margin-bottom: 0.5rem
                        letter-spacing: -0.05rem

                    h2
                        font-size: 1.2rem
                        letter-spacing: 0.1rem

    &.program

        .presentations
            margin: 2rem 1rem 10rem 1rem

            .card
                display: flex
                flex-direction: column
                height: 100%
                cursor: pointer

                .media-content

                    h1
                        font-size: 18px
                        margin-bottom: 0.5rem

                        &.keynote
                            font-size: 24px
                            margin-bottom: 1rem

                    h2
                        font-size: 12px
                        letter-spacing: 0.1rem

                        div
                            margin: 0.3rem

                            span
                                margin: 0.4rem 0.8rem 0 0.4rem

    &.schedule

        .schedule-table
            margin: 4rem 2rem 8rem 2rem

    &.venue

        /* empty */

    &.impressum

        /* empty */

    &.privacy

        /* empty */

    .footer
        margin-top: 4rem

        .column
            margin: 0 1rem

        .text
            font-weight: 500

            a
                color: $white

                &:hover
                    color: $grey

@media (max-width: 768px)
    .keynote
        margin: 3rem .2rem 4rem .2rem !important
